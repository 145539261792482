<template>
    <v-form ref="form" v-model="valid" lazy-validation class="text-center">
        <v-container
            ><v-card class=" mx-auto">
                <Page-Header
                    :title="$t('users.changePassword')"
                    icon="mdi-lock"
                >
                </Page-Header>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="changePasswordItem.oldPassword"
                                    :label="$t('users.oldPassword')"
                                    :rules="[rules.required]"
                                    type="password"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="changePasswordItem.password"
                                    :label="$t('users.newPassword')"
                                    :rules="[rules.required]"
                                    type="password"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    type="password"
                                    v-model="changePasswordItem.confirmPassword"
                                    :rules="[
                                        rules.required,
                                        rules.confirmPassword
                                    ]"
                                    :label="$t('users.confirmPassword')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="primary darken-1"
                        class="white--text"
                        @click="saveChangePassword"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        {{ $t("save") }}
                    </v-btn>
                </v-card-actions>
            </v-card></v-container
        >
    </v-form>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            loading: false,
            valid: true,
            changePasswordItem: {
                oldPassword: "",
                password: "",
                confirmPassword: ""
            },

            rules: {
                required: value => !!value || this.$t("ThisFieldIsRequired"),
                confirmPassword: () =>
                    this.changePasswordItem.confirmPassword ===
                        this.changePasswordItem.password ||
                    this.$t("users.notMatchPassword")
            }
        };
    },
    methods: {
        saveChangePassword() {
            this.loading = true;
            var val = this.$refs.form.validate();
            if (val) {
                axios
                    .post(
                        "Accounts/ChangePasswordByUser",
                        this.changePasswordItem
                    )
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.$router.push({ path: "/" });
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.loading = true;
            }
        }
    }
};
</script>
